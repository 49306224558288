<template>
  <ul class="card-container" :style="{ 'flex-direction': direction }">
    <li>
      <img :src="src" alt="" />
    </li>
    <li
      class="card-content"
      :style="{
        'padding-left': `${direction === Direction.ROW ? '50' : '0'}px`
      }"
    >
      <slot></slot>
    </li>
  </ul>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

enum Direction {
  COLUMN = 'column',
  ROW = 'row'
}

export default defineComponent({
  props: {
    direction: {
      type: String,
      default: Direction.COLUMN,
      validator: (value: string): boolean => Object.values(Direction).includes(value as Direction)
    },
    src: String
  },
  setup() {
    return { Direction };
  }
});
</script>

<style scoped lang="scss">
@import './Card.scss';
</style>
