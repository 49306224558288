
import { defineComponent } from 'vue';
import Banner from '@/shared/Banner.vue';
import BreadcrumbWrapper from '@/shared/BreadcrumbWrapper.vue';
import ContentWrapper from '@/shared/ContentWrapper.vue';
import Card from '@/shared/Card.vue';

export default defineComponent({
  components: {
    Banner,
    BreadcrumbWrapper,
    ContentWrapper,
    Card
  },
  setup() {
    const partners = ['OSSTEM', 'SCHEU', 'CA_DIGITAL', 'DENTCA', 'HUBIT', 'TOPLAN'];

    return { partners };
  }
});
