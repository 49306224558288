<template>
  <div>
    <banner>
      <template v-slot:image>
        <img src="@/assets/images/com_vs1.png" alt="" />
      </template>
      <template v-slot:content>About MagicLine</template>
    </banner>
    <breadcrumb-wrapper></breadcrumb-wrapper>
    <content-wrapper class="content-wrapper">
      <template v-slot:header>
        <h3 class="title_h2">MagicLine 이란?</h3>
      </template>
      <template v-slot:content>
        <card direction="row" :src="require('@/assets/images/intro_text1_right.png')">
          <div class="right-box">
            <div class="title_h2 mt30 mb20">디지털치과 토털 솔루션</div>
            <div class="title_h3">
              MagicLine은 디지털 치과 토털 솔루션을 통해 진료환경 개선과 치과치료 향상을 목적으로 하며, 고객의 성공을
              견인할 수 있도록 항상 고객과 함께합니다.
            </div>
            <div class="title_h2 mt30 mb20">Precise ∙ Easy ∙ Speedy</div>
            <div class="title_h3">
              MagicLine은 CAD/CAM 기술을 이용하여 치료 결과를 시뮬레이션하여 정확한 치료 계획을 수립하고 편리하고 빠른
              시술을 가능하게 하는 시스템입니다
            </div>
          </div>
        </card>
      </template>
    </content-wrapper>
    <content-wrapper class="content-wrapper-2">
      <template v-slot:header>
        <div class="title_h2"><strong>진료영역 확대</strong></div>
        <div class="title_h3">
          디지털 기술을 기반으로 기존 방법보다 쉽고 정확하게<br />
          모든 분야의 진료 영역으로 확대할 수 있도록 MagicLine이 함께합니다
        </div>
      </template>
      <template v-slot:content>
        <div class="m-guide">
          <div class="sec">
            <div class="txt-wrap">
              <div class="tit">기존</div>
              <div class="hline"></div>
              <div class="tit">보철중심</div>
            </div>
          </div>
          <div class="sec">
            <div class="txt-wrap">
              <div class="tit">매직라인</div>
              <div class="hline"></div>
              <div class="tit">보철 + <strong>교정 / 특수보철</strong></div>
            </div>
          </div>
        </div>
      </template>
    </content-wrapper>
    <content-wrapper class="content-wrapper-3">
      <template v-slot:header>
        <div class="title_h2">MagicLine <strong>파트너사</strong></div>
        <div class="title_h3" style="margin-bottom: 0px">
          MagicLine은 유수의 파트너사와의 협약으로 최선의 서비스를 제공하고자 노력합니다.
        </div>
      </template>
      <template v-slot:content>
        <div class="vline"></div>
      </template>
    </content-wrapper>
    <div class="content-wrapper-4">
      <ul>
        <li v-for="(partner, index) in partners" :key="index">
          <img :src="require(`@/assets/images/partners/${partner}.png`)" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Banner from '@/shared/Banner.vue';
import BreadcrumbWrapper from '@/shared/BreadcrumbWrapper.vue';
import ContentWrapper from '@/shared/ContentWrapper.vue';
import Card from '@/shared/Card.vue';

export default defineComponent({
  components: {
    Banner,
    BreadcrumbWrapper,
    ContentWrapper,
    Card
  },
  setup() {
    const partners = ['OSSTEM', 'SCHEU', 'CA_DIGITAL', 'DENTCA', 'HUBIT', 'TOPLAN'];

    return { partners };
  }
});
</script>

<style scoped lang="scss">
@import './AboutMagicLineView.scss';
</style>
