
import { defineComponent } from 'vue';

enum Direction {
  COLUMN = 'column',
  ROW = 'row'
}

export default defineComponent({
  props: {
    direction: {
      type: String,
      default: Direction.COLUMN,
      validator: (value: string): boolean => Object.values(Direction).includes(value as Direction)
    },
    src: String
  },
  setup() {
    return { Direction };
  }
});
